<h1 mat-dialog-title style="text-transform: none;">
  Email ID Card
</h1>

<form [formGroup]="emailDialogForm">
<div mat-dialog-content class="content">
  <p style="white-space: pre-line;">We will send the ID card to the email you indicate below</p>
  <div id="searchContainer">

    <mat-form-field>
      <mat-label>example@email.com"</mat-label>
      <input matInput type="email" formControlName="mailInput" class="input-search" email required  #mailInput name="mailInput">
      <mat-error *ngIf="emailDialogForm.controls['mailInput'].hasError('required')">Please provide an email</mat-error>
      <mat-error *ngIf="!emailDialogForm.controls['mailInput'].hasError('required') && (emailDialogForm.controls['mailInput'].hasError('email') || emailDialogForm.controls['mailInput'].status === 'INVALID')">Please provide a valid email</mat-error>
    </mat-form-field>
  </div>
</div>

<div mat-dialog-actions id="buttonsContainer">
  <div class="directButtonContainer cancel">
    <button class="buttonFont imageButton" (click)="onDismiss();" onclick="gtag('event', 'Email ID Card', {'event_name': 'Cancel Send Member Id Card','screen_name': 'Members List'})">Cancel</button>
  </div>
  <div class="directButtonContainer send">
    <button class="buttonFont imageButton" [ngClass]="!emailDialogForm.valid ? 'imageButtonDisabled' : ''" [disabled]="!emailDialogForm.valid" (click)="onConfirm();" onclick="gtag('event', 'Email ID Card', {'event_name': 'Send Member Id Card','screen_name': 'Members List'})">Send</button>
  </div>
</div>
</form>
