<footer id="footer" style="padding: 25px; background-color: #363636; position: relative; bottom: 0;">
  <div id="footer-container">
    <div style="display: flex; flex-wrap: wrap;">
      <div style="flex: 1; padding: 5px;">
        <div class="image">
          <img src="../../../../../assets/images/LiveFearless_BCBSNE_Lockup-300w.png" alt="BlueCross BlueShield Nebraska" style="max-width: 100%;">
        </div>
        
      </div>
      <div style="flex: 2; padding: 5px;">
        <div style="display: flex; flex-direction: column; justify-content: space-between; height: 100%; font-size: 16px;">
          <ul style="list-style: none; padding: 0; margin: 0; display: flex; justify-content: left">
            <li style="margin-bottom: 10px; ">
              <a href="https://www.nebraskablue.com/en/About-Us/Policies-and-Best-Practices/Health-Privacy" rel="noopener noreferrer" title="Privacy Policy" target="_blank" style="color: white; text-decoration: none; padding-right: 15px;">Privacy</a>
         
            </li>
            <li>
              <a href="https://www.nebraskablue.com/en/About-Us/Policies-and-Best-Practices/Usage-Agreement" rel="noopener noreferrer" title="Usage Agreement" target="_blank" style="color: white; padding-right: 15px;">Legal</a>
            </li>
            <li>
              <a href="https://www.nebraskablue.com/en/About-Us/Policies-and-Best-Practices/Non-Discrimination-Notice-and-Translations" rel="noopener noreferrer" title="Non-discrimination and translation" style="color: white; padding-right: 15px;" target="_blank">Non-discrimination and translation</a>
            </li>
            <!-- Add other footer links here -->
          </ul>
          <div class="footer text-left copyright" style="margin-top: auto; color: #909090">
            <small>
              &copy; 2024 &nbsp;Blue Cross and Blue Shield of Nebraska.<br>
              Blue Cross and Blue Shield of Nebraska is an independent licensee of the Blue Cross and Blue Shield Association. The Blue Cross and Blue Shield Association licenses Blue Cross and Blue Shield of Nebraska to offer certain products and services under the Blue Cross&reg; and Blue Shield&reg; brand names within the state of Nebraska.
            </small>
          </div>
        </div>
      </div>
      <div style="flex: 1; padding: 5px;">
        <div class="socialconnect hidden-xs hidden-sm hidden-md" style="margin-top: 10px;">
          <h3 class="subhead" style="color: white; border: none; font-size: 1.2em;display: flex; justify-content: left;">Connect with us</h3>
          <ul class="list-inline" style="display: flex; justify-content: left; font-size: 24px;">
            <li>
              <a href="https://www.facebook.com/BCBSNebraska/" rel="noopener noreferrer" target="_blank" title="Facebook">
                <span class="fa-stack">
                  <i class="fa fa-circle fa-stack-2x text-white"></i>
                  <i class="fa fa-facebook fa-stack fa-stack-1x text-blue"></i>
                </span>
              </a>
            </li>
            <li>
              <a href="https://twitter.com/BCBSNebraska" rel="noopener noreferrer" target="_blank" title="Twitter">
                <span class="fa-stack">
                  <i class="fa fa-circle fa-stack-2x text-white"></i>
                  <i class="fa fa-twitter fa-stack fa-stack-1x text-blue"></i>
                </span>
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/blue-cross-and-blue-shield-of-nebraska" rel="noopener noreferrer" target="_blank" title="LinkedIn">
                <span class="fa-stack">
                  <i class="fa fa-circle fa-stack-2x text-white"></i>
                  <i class="fa fa-linkedin fa-stack fa-stack-1x text-blue"></i>
                </span>
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/user/nebraskabluecross" rel="noopener noreferrer" target="_blank" title="YouTube">
                <span class="fa-stack">
                  <i class="fa fa-circle fa-stack-2x text-white"></i>
                  <i class="fa fa-youtube fa-stack fa-stack-1x text-blue"></i>
                </span>
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/bcbsnebraska/" rel="noopener noreferrer" target="_blank" title="Instagram">
                <span class="fa-stack">
                  <i class="fa fa-circle fa-stack-2x text-white"></i>
                  <i class="fa fa-instagram fa-stack fa-stack-1x text-blue"></i>
                </span>
              </a>
            </li>
          </ul>

        </div>
      </div>
    </div>
  </div>
</footer>
