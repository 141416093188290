import { FormsModule } from '../forms/forms.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrokerComponent } from './broker.component';
import { MaterialModule } from '../../material/material.module';
import { GroupListComponent } from './components/groups-list/groups-list.component';
import { GlpCommonModule } from 'src/app/glp-common/glp-common.module';
import { HomeModule } from '../home/home.module';
import { MembersListModule } from '../home/components/members-list/members-list.module';
import { MatPaginatorModule } from '@angular/material/paginator';
import { CompleteRegistrationComponent } from '../home/components/register/completeRegistration.component';
import { IndividuallistComponent } from '../home/components/individual-list/individuallist.component';



@NgModule({
    declarations: [GroupListComponent, BrokerComponent, IndividuallistComponent, CompleteRegistrationComponent],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    GlpCommonModule,
    HomeModule,
    MatPaginatorModule,
    MembersListModule
  ],
    entryComponents: [GroupListComponent, BrokerComponent, IndividuallistComponent, CompleteRegistrationComponent]
})
export class BrokerModule { }
