import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {
  debounceTime,
  distinctUntilChanged,
  startWith,
  tap,
  delay,
} from 'rxjs/operators';
import { merge, fromEvent } from 'rxjs';
import { MemberDataService } from '../../services/member-data.service';
import { MemberInfo } from '../../models/MemberInfo';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MemberDetailComponent } from '../member-detail/member-detail.component';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { LoggingService } from 'src/app/utilities/logging.service';
import { AlertDialogComponent } from 'src/app/glp-common/components/alert-dialog/alert-dialog.component';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../../../services/user.service';

@Component({
  selector: 'app-members-list',
  templateUrl: './members-list.component.html',
  styleUrls: ['./members-list.component.scss'],
})
export class MembersListComponent implements OnInit {
  groupId: number;
  selectedMemberId: number;
  enableSearch: boolean;
  idType:string;
  isGroup:boolean;
  coverageType:string;

  dataSource: MatTableDataSource<any>;

  displayedColumns: string[] = [
    'memberId',
    // 'accountId',
    'name',
    'dateOfBirth',
    'coverageType',
    'coverageStart',
    'coverageEnd',
    'dependent',
    'status'
    // 'planVersionEffectiveThruDt',
  ];

  filterStr: string = '';

  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild(MatSort) sort: MatSort;

  @ViewChild('searchInput') searchInput: ElementRef;

  constructor(
    private dataService: MemberDataService,
    private dialog: MatDialog,
    private loggingService: LoggingService,
    private userService: UserService,
    public oidcSecurityService: OidcSecurityService
  ) {}

  ngOnInit() {
    this.oidcSecurityService.getPayloadFromIdToken().subscribe((account)=>{
    const errorDialogConfig = new MatDialogConfig();

    this.groupId = this.dataService.selectedGroupID;

    if (account) {
      if(this.groupId == undefined || this.groupId == null)
      {
        this.groupId = +account.extension_AccountNumber;
      }

      let userEmail = "";
      if (account.emails?.length > 0) {
        userEmail = account.emails[0];
      }

      //default idType is groupId
      if(this.dataService.idType == undefined || this.dataService.idType == null){
        this.idType = 'groupId';
      }
      else{
        this.idType = this.dataService.idType;
      }

      //when called by Individual List, search by subscriberId
      if(this.idType == 'individualId'){
        //this.groupId = Number(this.dataService.selctedSubscriber);
        this.isGroup = false;
        this.coverageType = this.dataService.productType;
      }
      else{
        this.isGroup = true;
      }


      this.dataService.getMemberList(this.groupId, userEmail, this.idType, this.coverageType, this.dataService.selctedSubscriber).subscribe((list) => {

        if (list == null || list.length <= 0)
        {
          errorDialogConfig.data = {
            title: 'No Members found',
            message: 'No members were found for this group. Please try again later.',
            footermessage: 'Close the Browser to Exit',
          };
          var err = {
            name: 'Members List is empty',
            message: 'Members List is empty = ' + account.extension_AccountNumber,
            stack: 'Members List is empty',
          };
          this.loggingService.logException(err, 3);

          this.dialog.open(AlertDialogComponent, errorDialogConfig);
          this.dialog.afterAllClosed.subscribe((_) => this.oidcSecurityService.logoff());
        }

        this.enableSearch = true;

        let array = list.map((item) => {
          return {
            $key: item.memberId,
            ...item,
          };
        });
         this.dataSource = new MatTableDataSource(array);
        this.dataSource.filterPredicate = (data, filter) => {
          this.applyFilter(this.filterStr);
          return this.displayedColumns.some((ele) => {
            return data[ele].toLowerCase().indexOf(filter) != -1;
          });
        };
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = (item, property) => {
          switch (property) {
            case 'coverageStart': return new Date(item.coverageStart);
            case 'coverageEnd': return new Date(item.coverageEnd);
            case 'dateOfBirth': return new Date(item.dateOfBirth);
            default: return item[property];
          }
        };
        this.dataSource.paginator = this.paginator;
      },
      (error) => {
        if(error.status == 400){
          errorDialogConfig.data = {
            title: 'Invalid Group Information provided',
            message: 'The Group Information provided is either not associated with this broker or we are having trouble finding it. Please try again later.',
            footermessage: 'Close the Browser to Exit',
          };
          var err = {
            name: 'Invalid Group Information provided',
            message: 'Invalid Group Information provided = ' + account.extension_AccountNumber,
            stack: 'Invalid Group Information provided',
          };
          this.loggingService.logException(err, 3);

          this.dialog.open(AlertDialogComponent, errorDialogConfig);
          this.dialog.afterAllClosed.subscribe((_) => this.oidcSecurityService.logoff());
        }
        if(error.status == 500){
          errorDialogConfig.data = {
            title: 'Error retrieving members',
            message: 'We are currently having trouble retrieving group members. Please try again later.',
            footermessage: 'Close the Browser to Exit',
          };
          var err = {
            name: 'Error retrieving members',
            message: 'Error retrieving members = ' + account.extension_AccountNumber,
            stack: 'Error retrieving members',
          };
          this.loggingService.logException(err, 3);

          this.dialog.open(AlertDialogComponent, errorDialogConfig);
          this.dialog.afterAllClosed.subscribe((_) => this.oidcSecurityService.logoff());
        }
      });
    }
  });
  }

  applyFilter(filterValue: string) {
      this.filterStr = filterValue.trim();
      if(this.dataSource)
      {
      if (filterValue.trim() != "")
      {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
        this.dataSource.filterPredicate = (data: any, filter: string) => {

        var c1 = (typeof data.memberId !== 'undefined' && data.memberId !== null && data.memberId.includes(filter) ||
                 (typeof data.name !== 'undefined' && data.name !== null && data.name.toLowerCase().includes(filter)) ||
                 (typeof data.dateOfBirth !== 'undefined' && data.dateOfBirth !== null && data.dateOfBirth.includes(filter)) ||
                 (typeof data.coverageType !== 'undefined' && data.coverageType !== null && data.coverageType.toLowerCase().includes(filter)) ||
                 (typeof data.coverageStart !== 'undefined' && data.coverageStart !== null && data.coverageStart.includes(filter)) ||
                 (typeof data.coverageEnd !== 'undefined' && data.coverageEnd !== null && data.coverageEnd.includes(filter)) ||
                 (typeof data.dependent !== 'undefined' && data.dependent !== null && data.dependent.toLowerCase().includes(filter)) ||
                 (typeof data.status !== 'undefined' && data.status !== null && data.status.toLowerCase().includes(filter))
        );

        return c1;
        };
        this.dataSource.filter = filterValue;
      }

        this.dataSource.filter = filterValue;
      }
  }
  @HostListener('window:popstate', ['$event'])
  onPopState(event: Event) {
    // This method will be called when the user clicks the back button
    console.log('Back button clicked');
    this.userService.setGroupLeaderAccountIsSelected(false);
  }

  clickFilter() {
    this.dataSource.filter = this.searchInput.nativeElement.value.trim().toLowerCase();
  }

  openDetails(row: any) {
    this.selectedMemberId = row.memberId;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '45%';
    dialogConfig.minHeight = '75%';
    dialogConfig.data = row;
    dialogConfig.position = { right: '0px', top: '25px' };
    dialogConfig.panelClass = 'mat-dialog-member-details-container';
    this.dialog.open(MemberDetailComponent, dialogConfig);
  }
}
