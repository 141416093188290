<h1 id="titleDiv">{{title}}</h1>

<div id="memberInformationDiv">Member Information:</div>
<table mat-table [dataSource]="dataSource1" class="mat-elevation-z8">
  
    <!-- Gender Column -->
    <ng-container matColumnDef="gender">
      <th mat-header-cell *matHeaderCellDef>Gender</th>
      <td mat-cell *matCellDef="let element1">{{element1.gender}}</td>
    </ng-container>
  
    <!-- Relation Column -->
    <ng-container matColumnDef="relation">
      <th mat-header-cell *matHeaderCellDef>Relation</th>
      <td mat-cell *matCellDef="let element1">{{element1.relation}}</td>
    </ng-container>
  
    <!-- MemberId Column -->
    <ng-container matColumnDef="memberId">
      <th mat-header-cell *matHeaderCellDef>Member Id</th>
      <td mat-cell *matCellDef="let element1">{{element1.memberId}}</td>
    </ng-container>

    <!-- MemberName Column -->
    <ng-container matColumnDef="memberName">
      <th mat-header-cell *matHeaderCellDef>Member Name</th>
      <td mat-cell *matCellDef="let element1">{{element1.memberName}}</td>
    </ng-container>
  
    <!-- Address Column -->
    <ng-container matColumnDef="memberAddress">
      <th mat-header-cell *matHeaderCellDef>Address</th>
      <td mat-cell *matCellDef="let element1">{{element1.memberAddress}}</td>
    </ng-container>

    <!-- City Column -->
    <ng-container matColumnDef="memberCityStPin">
        <th mat-header-cell *matHeaderCellDef>City,State,Zip</th>
        <td mat-cell *matCellDef="let element1">{{element1.memberCityStPin}}</td>
    </ng-container>

    <!-- DateOfBirth Column -->
    <ng-container matColumnDef="dateOfBirth">
        <th mat-header-cell *matHeaderCellDef>Date Of Birth</th>
        <td mat-cell *matCellDef="let element1">{{element1.dateOfBirth}}</td>
    </ng-container>

    <!-- Start Date Column -->
    <ng-container matColumnDef="startDt">
        <th mat-header-cell *matHeaderCellDef>Effective Date</th>
        <td mat-cell *matCellDef="let element1">{{element1.startDt}}</td>
    </ng-container>

    <!-- End Date Column -->
    <ng-container matColumnDef="endDt">
        <th mat-header-cell *matHeaderCellDef>Term Date</th>
        <td mat-cell *matCellDef="let element1">{{element1.endDt}}</td>
    </ng-container>

    <ng-container matColumnDef="loading1">
      <mat-footer-cell *matFooterCellDef colspan="9">
        Loading data...
      </mat-footer-cell>
    </ng-container>
    <ng-container matColumnDef="noData1">
      <mat-footer-cell *matFooterCellDef colspan="9">
        No data.
      </mat-footer-cell>
    </ng-container>

    <tr mat-header-row style="height: 20px" *matHeaderRowDef="displayedColumns1"></tr>
    <tr mat-row style="height: 20px" *matRowDef="let row; columns: displayedColumns1;"></tr>
    <mat-footer-row *matFooterRowDef="['loading1']" [ngClass]="{'hide':dataSource1!=null}"></mat-footer-row>
  <mat-footer-row *matFooterRowDef="['noData1']" [ngClass]="{'hide':!(dataSource1!=null && dataSource1.data.length==0)}"></mat-footer-row>
</table>

<br/>
<div id="coverageInformationDiv">Coverage Information:</div>
<div class="coverageInfoDiv mat-elevation-z8">
<table mat-table [dataSource]="dataSource2">
  
  <!-- coverageTypeTxt Column -->
  <ng-container matColumnDef="coverageTypeTxt">
    <th mat-header-cell *matHeaderCellDef>Coverage Type</th>
    <td mat-cell *matCellDef="let element2">{{element2.coverageTypeTxt}}</td>
  </ng-container>

  <!-- coverageTierTxt Column -->
  <ng-container matColumnDef="coverageTierTxt">
    <th mat-header-cell *matHeaderCellDef>Coverage Tier</th>
    <td mat-cell *matCellDef="let element2">{{element2.coverageTierTxt}}</td>
  </ng-container>

  <!-- nationalContractPrefixCd Column -->
  <ng-container matColumnDef="nationalContractPrefixCd">
    <th mat-header-cell *matHeaderCellDef>PrefixCd</th>
    <td mat-cell *matCellDef="let element2">{{element2.nationalContractPrefixCd}}</td>
  </ng-container>

  <!-- benefitPlanDesc Column -->
  <ng-container matColumnDef="benefitPlanDesc">
    <th mat-header-cell *matHeaderCellDef>Benefit Plan Description</th>
    <td mat-cell *matCellDef="let element2">{{element2.benefitPlanDesc}}</td>
  </ng-container>

  <!-- productDesc Column -->
  <ng-container matColumnDef="productDesc">
    <th mat-header-cell *matHeaderCellDef>Product Description</th>
    <td mat-cell *matCellDef="let element2">{{element2.productDesc}}</td>
  </ng-container>

  <!-- coverageFromDt Column -->
  <ng-container matColumnDef="coverageFromDt">
    <th mat-header-cell *matHeaderCellDef>Coverage From Date</th>
    <td mat-cell *matCellDef="let element2">{{element2.coverageFromDt}}</td>
  </ng-container>

  <!-- coverageThruDt Column -->
  <ng-container matColumnDef="coverageThruDt">
      <th mat-header-cell *matHeaderCellDef>Coverage Thru Date</th>
      <td mat-cell *matCellDef="let element2">{{element2.coverageThruDt}}</td>
  </ng-container>

  <ng-container matColumnDef="loading2">
    <mat-footer-cell *matFooterCellDef colspan="7">
      Loading data...
    </mat-footer-cell>
  </ng-container>
  <ng-container matColumnDef="noData2">
    <mat-footer-cell *matFooterCellDef colspan="7">
      No records to display.
    </mat-footer-cell>
  </ng-container>

  <tr mat-header-row style="height: 20px" *matHeaderRowDef="displayedColumns2; sticky: true"></tr>
  <tr mat-row style="height: 20px" *matRowDef="let row; columns: displayedColumns2;"></tr>
  <mat-footer-row *matFooterRowDef="['loading2']" [ngClass]="{'hide':dataSource2!=null}"></mat-footer-row>
  <mat-footer-row *matFooterRowDef="['noData2']" [ngClass]="{'hide':!(dataSource2!=null && dataSource2.data.length==0)}"></mat-footer-row>
</table>

<mat-paginator style="height: 20px"
               [pageSize]="5" 
               [pageSizeOptions]="[5]"
               [showFirstLastButtons]="true" 
               [hidePageSize]="true">
</mat-paginator>
</div>

<br/>
<div id="otherInsuranceHide" *ngIf="isShown"> 
<div id="otherInsuranceDiv" *ngIf="showBenefits">Coordination of Benefits:</div>
<table mat-table [dataSource]="dataSource3" class="mat-elevation-z8" *ngIf="showBenefits"> 
  
  <!-- coverageTypeTxt Column -->
  <ng-container matColumnDef="coverageTypeTxt">
    <th mat-header-cell *matHeaderCellDef>Coverage Type</th>
    <td mat-cell *matCellDef="let element3">{{element3.coverageTypeTxt}}</td>
  </ng-container>

  <!-- otherInsurancePolicyId Column -->
  <ng-container matColumnDef="otherInsurancePolicyId">
    <th mat-header-cell *matHeaderCellDef>Other Insurance PolicyId</th>
    <td mat-cell *matCellDef="let element3">{{element3.otherInsurancePolicyId}}</td>
  </ng-container>

  <!-- otherInsuranceName Column -->
  <ng-container matColumnDef="otherInsuranceName">
    <th mat-header-cell *matHeaderCellDef>Other Insurance</th>
    <td mat-cell *matCellDef="let element3">{{element3.otherInsuranceName}}</td>
  </ng-container>

  <!-- classificationTxt Column -->
  <ng-container matColumnDef="classificationTxt">
    <th mat-header-cell *matHeaderCellDef>Classification</th>
    <td mat-cell *matCellDef="let element3">{{element3.classificationTxt}}</td>
  </ng-container>

  <!-- effectiveFromDt Column -->
  <ng-container matColumnDef="effectiveFromDt">
    <th mat-header-cell *matHeaderCellDef>Effective From Date</th>
    <td mat-cell *matCellDef="let element3">{{element3.effectiveFromDt}}</td>
  </ng-container>

  <!-- effectiveThruDt Column -->
  <ng-container matColumnDef="effectiveThruDt">
      <th mat-header-cell *matHeaderCellDef>Effective Thru Date</th>
      <td mat-cell *matCellDef="let element3">{{element3.effectiveThruDt}}</td>
  </ng-container>

  <ng-container matColumnDef="noRecord">
    <td mat-footer-cell *matFooterCellDef>No records found.</td>
  </ng-container>

  <ng-container matColumnDef="loading3">
    <mat-footer-cell *matFooterCellDef colspan="6">
      Loading data...
    </mat-footer-cell>
  </ng-container>
  <ng-container matColumnDef="noData3">
    <mat-footer-cell *matFooterCellDef colspan="6">
      No records to display.
    </mat-footer-cell>
  </ng-container> 

  <tr mat-header-row style="height: 20px" *matHeaderRowDef="displayedColumns3"></tr>
  <tr mat-row style="height: 20px" *matRowDef="let row; columns: displayedColumns3;"></tr>
  <mat-footer-row *matFooterRowDef="['loading3']" [ngClass]="{'hide':dataSource3!=null}"></mat-footer-row>
  <mat-footer-row *matFooterRowDef="['noData3']" [ngClass]="{'hide':!(dataSource3!=null && dataSource3.data.length==0)}"></mat-footer-row>
</table>

</div>

<br/>
<div id="buttonsContainer">
    <div>
        <button class="buttonFont imageButton" (click)="onConfirm();">Close</button>
    </div>
</div>




