import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import * as FileSaver from 'file-saver';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {
  debounceTime,
  distinctUntilChanged,
  startWith,
  tap,
  delay,
} from 'rxjs/operators';
import { merge, fromEvent } from 'rxjs';
import { MemberDataService } from 'src/app/content/home/services/member-data.service';
import { UserService } from 'src/app/services/user.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { LoggingService } from 'src/app/utilities/logging.service';
import { AlertDialogComponent } from 'src/app/glp-common/components/alert-dialog/alert-dialog.component';
import { Router } from '@angular/router';
import { GroupInfo } from '../../models/GroupInfo';
import { FormsModule } from '@angular/forms';


@Component({
  selector: 'app-group-list',
  templateUrl: './groups-list.component.html',
  styleUrls: ['./groups-list.component.scss'],
})
export class GroupListComponent implements OnInit {
  groupId: number;
  enableSearch: boolean;
  user: string;
  dataSource: MatTableDataSource<any>;
  groupInfoList: GroupInfo[];
  searchText: string;
  brokerIdFilter: string;
  filterStr = '';

  displayedColumns: string[] = [
    'groupName',
    'groupID',
    'brokerName',
    'brokerID',
    'renewalMonth',
    'productName',
    'icon'
  ];

  months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  filteredValues =
  {
    brokerId: '',
    renewalMonth: '',
    productName: ''
  };

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator; // For pagination
  @ViewChild(MatSort, {static: false}) sort: MatSort; // For Sort

  @ViewChild('searchInput', {static: false}) searchInput: ElementRef;

  constructor(
    private dataService: MemberDataService,
    private dialog: MatDialog,
    private router: Router,
    public oidcSecurityService: OidcSecurityService,
    private loggingService: LoggingService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.user = this.userService.getUser().firstName + " " + this.userService.getUser().lastName;
    this.dataService.userName = this.user;
    this.dataService.notify({ userAdded: true });
    this.oidcSecurityService.getPayloadFromIdToken().subscribe((account)=>{
    const errorDialogConfig = new MatDialogConfig();

    this.dataService.notify({ isReload: true });

    if (account) {
      this.dataService.getGroupList().subscribe((result) => {

        let list = result.retList || new Array<GroupInfo>();

        if (!result.success)
        {

          if(result.status == 500){
            errorDialogConfig.data = {
              title: 'Group Data Under Maintenance',
              message: 'We are experiencing technical difficulties and are actively working on a fix. Please try again later.',
              footermessage: 'Close the Browser to Exit',
            };
            var err = {
              name: 'Group List is empty',
              message: 'Group List is empty = ' + account.extension_AccountNumber,
              stack: 'Group List is empty',
            };
            this.loggingService.logException(err, 3);
  
            this.dialog.open(AlertDialogComponent, errorDialogConfig);
            this.dialog.afterAllClosed.subscribe((_) => this.oidcSecurityService.logoff());
          }
        }

        this.enableSearch = true;
        let array = list.map((item) => {
          return {
            ...item,
            groupName: this.toCamelCase(item.groupName),
            brokerName: this.toCamelCase(item.brokerName)
          };
        });
        this.groupInfoList = list;
        this.dataSource = new MatTableDataSource(array);        
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        this.dataSource.sortingDataAccessor = (item, property) => {
          switch (property) {
            case 'renewalMonth': {
              return this.months.indexOf(item.renewalMonth);
            }
            default: return item[property];
          }
        };

        this.dataSource.filterPredicate = this.customFilterPredicate();
      });
    }
  });
  }
  toCamelCase(name: string): string {
    return name.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
  }

  exportToCSV() {
    const csvData = this.dataSource.filteredData.map(row => ({
      GroupName: row.groupName,
      GroupID: row.groupID,
      BrokerName: row.brokerName,
      BrokerID: row.brokerID,
      RenewalMonth: row.renewalMonth,
      ProductName: row.productName,
    }));

    const header = Object.keys(csvData[0]);
    const csv = [
      header.join(','),
      ...csvData.map(row => header.map(fieldName => JSON.stringify(row[fieldName])).join(','))
    ].join('\r\n');

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
    FileSaver.saveAs(blob, 'group-data.csv');
  }


  customFilterPredicate() {
    const myFilterPredicate = (data: GroupInfo, filter: string): boolean => {
      var globalMatch = !this.filterStr;

      if (this.filterStr) {
        // search all text fields
        var globalMatch = (typeof data.groupName !== 'undefined' && data.groupName !== null && data.groupName.toLowerCase().includes(this.filterStr.toLowerCase()) && this.toCamelCase(data.groupName).toLowerCase().includes(this.filterStr.toLowerCase()) ||
        (typeof data.groupID !== 'undefined' && data.groupID !== null && data.groupID.toLowerCase().includes(this.filterStr.toLowerCase())) ||
        (typeof data.brokerID !== 'undefined' && data.brokerID !== null && data.brokerID.includes(this.filterStr.toLowerCase())) ||
        (typeof data.brokerName !== 'undefined' && data.brokerName !== null && data.brokerName.toLowerCase().includes(this.filterStr.toLowerCase()) && this.toCamelCase(data.brokerName).toLowerCase().includes(this.filterStr.toLowerCase())) ||
        (typeof data.renewalMonth !== 'undefined' && data.renewalMonth !== null && data.renewalMonth.toLowerCase().includes(this.filterStr.toLowerCase())) ||
        (typeof data.productName !== 'undefined' && data.productName !== null && data.productName.toLowerCase().includes(this.filterStr.toLowerCase()))
      );
      console.log(globalMatch);
      }

      // if none of fields contain the global search, then we just return, otherwise we look at column specific values
      if (!globalMatch) {
        return;
      }

      let filterValues = JSON.parse(filter);
      let allColumnsMatch = true;

      if(filterValues.brokerId && filterValues.brokerId != ('-Select Broker-')){
        // Broker id comes in the format of FirstName LastName (BrokerId)
        // logic below splits off firstname lastname and removes parentheses
        let brokerWritingNumber = filterValues.brokerId.substring(filterValues.brokerId.indexOf("(") + 1).split(')')[0];
        allColumnsMatch = (typeof data.brokerID !== 'undefined' && data.brokerID !== null && data.brokerID.includes(brokerWritingNumber.toLowerCase())) && allColumnsMatch;
      }

      if(filterValues.renewalMonth && filterValues.renewalMonth != ('-Select Month-')){
        allColumnsMatch = (typeof data.renewalMonth !== 'undefined' && data.renewalMonth !== null && data.renewalMonth.toLowerCase().includes(filterValues.renewalMonth.toLowerCase())) && allColumnsMatch;
      }

      if(filterValues.productName && filterValues.productName != ('-Select Product-')){
        allColumnsMatch = (typeof data.productName !== 'undefined' && data.productName !== null && data.productName.toLowerCase().includes(filterValues.productName.toLowerCase())) && allColumnsMatch;
      }

      return allColumnsMatch;
    }
    return myFilterPredicate;
  }

  applyFilter() {
    this.dataSource.filter = JSON.stringify(this.filteredValues);
  }

  getBrokerIds(){
    return ['-Select Broker-',...new Set(this.groupInfoList.map(item => item.brokerName + " (" + item.brokerID + ") "))].sort();
  }

  getRenewalMonths(){ 
    return ['-Select Month-', ...this.months];
  }

  getProductNames(){
    return ['-Select Product-',...new Set(this.groupInfoList.map(item => item.productName))].sort();
  }

  updateSearchText(filterValue: string){
    this.filterStr = filterValue.trim();
    this.applyFilter();
  }

  updateBrokerIdFilter(brokerId: string){
    this.filteredValues.brokerId = brokerId;
    this.applyFilter();
  }

  updateRenewalMonthFilter(renewalMonth: string){
    this.filteredValues.renewalMonth = renewalMonth;
    this.applyFilter();
  }
  

  updateProductNameFilter(productName: string){
    this.filteredValues.productName = productName;
    this.applyFilter();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator; // For pagination
    this.dataSource.sort = this.sort; // For sort
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'renewalMonth': {
          return this.months.indexOf(item.renewalMonth);
        }
        default: return item[property];
      }
    };
  }
  

  clickFilter() {
    this.dataSource.filter = this.searchInput.nativeElement.value.trim().toLowerCase();
  }

  openGroup(row: any) {
    this.dataService.selectedGroupID = row.groupID;
    this.dataService.selectedGroupName = row.groupName;

    sessionStorage.setItem('accountType', row.groupType);
    this.dataService.idType = "groupId";
    this.dataService.formType='Group';

    this.dataService.notify({isReload: true});
    this.router.navigate(['/home']);
  
  } 
}
