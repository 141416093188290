<div id="full-container">
    <div></div>
    <div class="grid-container">
        <div>
          <h6 id="NotificationsHeading">Notifications</h6>
        
          <mat-accordion displayMode="flat" multi  >
            <span *ngIf="!loading && !availableNotifications">There are no new notifications.</span>
            <span *ngIf="loading">Loading notifications...</span>

            <mat-expansion-panel (opened)="onExpand(item.title)" *ngFor="let item of (displayedRows$ | async);" class="mat-elevation-z2">
              <mat-expansion-panel-header class="notification-row">
                <mat-panel-title>
                  <span class="notification-title">{{item.title}}</span>

                </mat-panel-title>
                <mat-panel-description>
                  <span class="notification-date">{{item.startDate}}</span>
                </mat-panel-description>
                
              </mat-expansion-panel-header>
              <div >
                <span class="tab notification-body">{{item.body}}</span>
              </div>
            </mat-expansion-panel>

          </mat-accordion>
          <mat-paginator #paginator [hidden]="!availableNotifications"
            [length]="totalRows$ | async"
            [pageIndex]="0"
            [pageSize]="10"
            [pageSizeOptions]="[1, 10, 25, 100]"
          ></mat-paginator>
        </div>
        
    </div>
    <div></div>
</div>


