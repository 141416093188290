<div id="full-container">
    <div></div>
    <div class="grid-container">
        <div>

            <h6 id="DocumentHeading">Plan Documents</h6>
            <div id="searchContainer">
                <input [disabled]="!enableSearch" type="text" class="input-search" (keyup)="applyFilter($event.target.value);" placeholder="Search by Document Name" #searchInput>
                
                <button [disabled]="!enableSearch" class="searchButton" (click)="clickFilter();" onclick="gtag('event', 'SearchPlanDocument', {'event_name': 'Search Plan Documents','screen_name': 'Plan Document List'});">Search</button>
            </div>
            
            <mat-table class="documents-table mat-elevation-z8" [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc" matSortDisableClear>

                <ng-container matColumnDef="name">
        
                    <mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="{'table-header':true}">Document Name</mat-header-cell>
        
                    <mat-cell *matCellDef="let document">{{document.name}}</mat-cell>
        
                </ng-container>
        
                <ng-container matColumnDef="downloadLink">
        
                    <mat-header-cell *matHeaderCellDef [ngClass]="{'table-header':true}">Actions</mat-header-cell>
        
                    <mat-cell *matCellDef="let document">
                      
                      <button class="downloadButton" (click)="downloadFile(document.name, document.fullName);" onclick="gtag('event', 'downloadPlanFile', {'event_name': 'Download Plan Documents','screen_name': 'Plan Page'});">               
                        <img class="downloadIcon" src="../../../../../assets/images/icons/download3.png" />
                        <span>Download</span>
                      </button>
                    </mat-cell>
        
                </ng-container>

                <ng-container matColumnDef="loading">
                    <mat-footer-cell *matFooterCellDef colspan="2">
                      Loading data...
                    </mat-footer-cell>
                  </ng-container>
                  <ng-container matColumnDef="noData">
                    <mat-footer-cell *matFooterCellDef colspan="2">
                      No data.
                    </mat-footer-cell>
                  </ng-container>
        
        
                <mat-header-row *matHeaderRowDef="displayedColumns" ></mat-header-row>
        
                <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

                <mat-footer-row *matFooterRowDef="['loading']" [ngClass]="{'hide':dataSource!=null}"></mat-footer-row>
                <mat-footer-row *matFooterRowDef="['noData']" [ngClass]="{'hide':!(dataSource!=null && dataSource.data.length==0)}"></mat-footer-row>

                
            </mat-table>
        
        
            <mat-paginator [pageSize]="10"
            [pageSizeOptions]="[1, 10, 25, 100]" [showFirstLastButtons]="true"
                           ></mat-paginator>
        
        
        </div>
        
    </div>
    <div></div>
</div>


