import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { AppConfigService } from '../../../app.config.service';
import { OrderCardRequest } from '../models/OrderCardRequest';
import { MemberInfo } from '../models/MemberInfo';
import { MembersData } from '../models/MembersData';
import { MemberCoverageInfo } from '../models/MemberCoverageInfo';
import { MemberOtherInsurance } from '../models/MemberOtherInsurance';
import { GLPNotification } from '../models/GLPNotification';
import { ImageData } from '../models/ImageData';
import { delay,map, observeOn, retry, retryWhen, take, tap } from 'rxjs/operators';
import * as SampleJson from "../../../../assets/json/response.json";
import { UserService } from 'src/app/services/user.service';
import { GroupInfo } from 'src/app/content/broker/models/GroupInfo';
import { HeaderComponent } from 'src/app/layout/header/header.component';
import { BehaviorSubject } from 'rxjs';
import { IndividualInfo } from '../../broker/models/IndividualInfo';


@Injectable({
  providedIn: 'root'
})
export class MemberDataService {

  private settingsUrl: string;
  private reportUrl: string;

  private messageSource = new BehaviorSubject('default message');
  public currentMessageSubscriber = this.messageSource.asObservable();

  private selectedSubscriberSource = new BehaviorSubject<string | null>(null);
  selectedSubscriber = this.selectedSubscriberSource.asObservable();

  constructor(private http: HttpClient,
    private appConfigService: AppConfigService,
    private userService: UserService
    ) {

  }

  public selectedGroupID: number;
  public selectedGroupName: string;
  public selctedSubscriber: string;
  public idType:string;
  public userName: string;
  public formType:string;
  public productType:string;

  notify(loadHeader: any) {
    this.messageSource.next(loadHeader)
  }

  setSelectedSubscriber(subscriber: string | null) {
    this.selectedSubscriberSource.next(subscriber);
  }

  getNotifications(): Observable<GLPNotification[]> {
    return this.http.get<GLPNotification[]>(`${this.appConfigService.AppConfig.apiUrl_glp}/Notifications`);
  }

  getMemberList(groupId: number, email: string, idType:string, coverageType:string, subscriberId:string): Observable<MemberInfo[]> {
    return this.http.get<MemberInfo[]>(`${this.appConfigService.AppConfig.apiUrl_glp}/Members`, {
        params: new HttpParams()
            .set('value', groupId.toString())
            .set('valueType', idType)
            .set('email', email)
            .set('coverageType', coverageType)
            .set('type', idType)
            .set('subscriberId',subscriberId)
    });
  }

    getGroupList(): Observable<any> {

      return new Observable(observer => {
        this.userService.GetBrokerGroups().subscribe(async res => {
          observer.next(res);
          observer.complete();
        });
      });
  }

  getSubscriberDetails(subscriptionId: number, groupID: string, email, idType:string, coverageType:string): Observable<MembersData[]>
  {
    return this.http.get<MembersData[]>(`${this.appConfigService.AppConfig.apiUrl_glp}/Members`, {
        params: new HttpParams()
            .set('value', subscriptionId.toString())
            .set('valueType', 'subscriptionId')
            .set('groupId', groupID)
            .set('email', email)
            .set('type', idType)
            .set('coverageType', coverageType)
    });
  }



  getMemberInformation(memberId: number,groupId: string, idType: string): Observable<MembersData[]>
  {
    return this.http.get<MembersData[]>(`${this.appConfigService.AppConfig.apiUrl_glp}/MemberData`, {
        params: new HttpParams()
            .set('memberId', memberId.toString())
            .set('groupId', groupId)
            .set('type', idType)
    });
  }

  getMemberCoverageInfo(memberId: number, groupId: string, idType: string, productType:string): Observable<MemberCoverageInfo[]>
  {
    return this.http.get<MemberCoverageInfo[]>(`${this.appConfigService.AppConfig.apiUrl_glp}/MemberCoverageInfo`, {
        params: new HttpParams()
            .set('memberId', memberId.toString())
            .set('groupId', groupId.toString())
            .set('type', idType)
            .set('coverageType', productType)
    });
  }

  getMemberOtherInsurance(memberId: number, groupId: string, idType:string): Observable<MemberOtherInsurance[]>
  {
    return this.http.get<MemberOtherInsurance[]>(`${this.appConfigService.AppConfig.apiUrl_glp}/MemberOtherInsurance`, {
        params: new HttpParams()
            .set('memberId', memberId.toString())
            .set('groupId', groupId)
            .set('type', idType)
    });
  }


  orderIdCard(recipientId:string, recipientName:string, submitter: string, groupId:string, userEmail:string, coverageEnd:string, idType:string): Observable<any> {
    let request:OrderCardRequest = {
      recipientId:recipientId,
      submitterUserName:submitter,
      recipientName:recipientName,
      groupId:groupId,
      submitterEmail:userEmail,
      coverageThruDt:coverageEnd
    };
    // let request:OrderCardRequest = {
    //   recipientId:'10008516700',
    //   submitterUserName:'Nicolas.Barredo4@test.com',
    //   recipientName:'John Test2',
    //   groupId:'103559'
    // };
    return this.http.post(`${this.appConfigService.AppConfig.apiUrl_glp}/RequestCard`, request, {
      params: new HttpParams()
      .set('value', groupId)
      .set('valueType', 'groupId')
      .set('type', idType)
    });
    //return of("");
  }

  getDoctorLink(groupId: string, uniquePlanId:string, userEmail:string, memberId:string, idType:string): Observable<any> {

    return this.http.get(`${this.appConfigService.AppConfig.apiUrl_glp}/FindDoctor`, {
        params: new HttpParams()
            .set('groupid', groupId)
            .set('uniquePlanId', uniquePlanId)
            .set('userEmail', userEmail)
            .set('memberId', memberId)
            .set('type', idType)
    });
  }

  downloadIdCard(recipientId:string, glpEmail:string, idType:string): Observable<any> {
    recipientId=recipientId.substring(0,9);
    return this.http.get<ImageData[]>(`${this.appConfigService.AppConfig.apiUrl_glp}/MacessImaging`, {
      observe:'response',
      params: new HttpParams()
          .set('recipientId', recipientId.toString())
          .set('email', "")
          .set('download', 'true')
          .set('glpEmail', glpEmail.toString())
          .set('type', idType)
    }) // retry logic user story#1173573
    .pipe(tap(res =>
      {
      if (res.status===200 && res.body.length === 0)
        {

         throwError("Empty Response Body with  200 status code");
        }
        else if  (res.status===200 && (res.body===undefined || res.body.length === 0))
        {
          throwError("Invalid Response Body with status code: "+ res.status);

        }
        else if (res.status !==200 && (res.body===undefined || res.body.length === 0 || res.body === null))
        {
          throwError("Error response with status code: "+ res.status);
        }

  }),
      retry(2),
     map(res=>{

      /*
      var jsonRes = JSON.parse(res);
      if(!jsonRes || jsonRes.length == 0){
        return null;
      }

      const contentType = 'image/png';
      let parsed = jsonRes[0];
      console.log('res parsed = ' + parsed);
      let data = {
        imageFront: this.b64toBlob(parsed.FrontImageData, contentType),
        imageBack: this.b64toBlob(parsed.BackImageData, contentType),
      }
      return data ;
      */

      //console.log('res1 = ' + res);
      //console.log('frontimage = ' + res[0]);
      //console.log('backimage = ' + res[1]);

      const contentType = 'image/png';
      if (res.body.length > 0)
      {


      let data = {
        imageFront: this.b64toBlob(res.body[0], contentType),
        imageBack: this.b64toBlob(res.body[1], contentType),
      }
      return data ;
    }
    }));
  }

  emailIdCard(recipientId:string, email:string, glpEmail:string, idType:string): Observable<any> {
    recipientId=recipientId.substring(0,9);
    return this.http.get<ImageData[]>(`${this.appConfigService.AppConfig.apiUrl_glp}/MacessImaging`, {
      params: new HttpParams()
          .set('recipientId', recipientId.toString())
          .set('recipientEmail', email.toString())
          .set('glpEmail', glpEmail.toString())
          .set('type', idType)
    })
    .pipe(map(res=>{

      /*
      var jsonRes = JSON.parse(res);
      if(!jsonRes || jsonRes.length == 0){
        return null;
      }
      const contentType = 'image/png';
      let parsed = jsonRes[0];
      if(parsed != null || parsed != undefined)
        return true ;
      */

      if (res[0] != null || res[0] != undefined){
        return true;
      }
      else{
        return null;
      }

    }));
  }

  private b64toBlob(b64Data, contentType='', sliceSize=512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }



}

