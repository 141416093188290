<div id="full-container">
    <div></div>
    <div>
      <div class="buttonFont imageButton">
        <img class="imagePadding" src="../../../../../assets/images/icons/info.png">
        New here?
        <a class="guide-link" (click)="getGuide();" onclick="gtag('event', 'FAQ Guide', {'event_name': 'Open User Guide','screen_name': 'FAQ Guide'});"> Click to see our User Guide</a>
      </div>
    </div>
    <div></div>
</div>


