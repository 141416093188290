import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {
  debounceTime,
  distinctUntilChanged,
  startWith,
  tap,
  delay,
} from 'rxjs/operators';
import { merge, fromEvent } from 'rxjs';
import { MemberDataService } from '../../services/member-data.service';
import { MemberInfo } from '../../models/MemberInfo';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NotificationDialogComponent } from '../notification-dialog/notification-dialog.component';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Router } from '@angular/router';
import { ActivityLogService } from '../../../../services/activity-log.service';
import { ActivityType } from 'src/app/models/ActivityType';
import { LoggingService } from 'src/app/utilities/logging.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss'],
})
export class NotificationListComponent implements OnInit {
  groupId: number;
  loading:boolean=false;
  userEmail:string;
  selectedTitle: string;
  notificationListCount: number;

  dataSource: MatTableDataSource<any>;

  displayedColumns: string[] = [
    'title'
  ];

  constructor(
    private dataService: MemberDataService,
    private dialog: MatDialog,
    public oidcSecurityService: OidcSecurityService,
    private router: Router,
    private userService: UserService,
    private logService: ActivityLogService,
    private loggingService: LoggingService
  ) {}

  ngOnInit() {
    this.oidcSecurityService.getPayloadFromIdToken().subscribe((account)=>{

    if (account) {
      this.groupId = +account.extension_AccountNumber;
      this.loading = true;
      if(account.emails?.length>0){
        this.userEmail = account.emails[0];
      }
      this.dataService.getNotifications().subscribe((list) => {
        this.loading = false;
        this.notificationListCount = list.length;
        list = list.slice(0, 2);
        let array = list.map((item) => {
          return {
            $key: item.title,
            ...item,
          };
        });
        this.dataSource = new MatTableDataSource(array);
      },e=>this.loading = false);
    }
  });
  }

  openDetails(row: any) {
    this.logService.log(this.userEmail, this.userService.isBrokerUser(),ActivityType.ViewNotificationDetail,row.title).subscribe(
      (data) => {
      },
      (err) => {
        this.loggingService.logException(err, 4);
      }
    );
    this.selectedTitle = row.title;
      const notificationDialogConfig = new MatDialogConfig();
      notificationDialogConfig.width ="600px"; 
      notificationDialogConfig.data = {
        title: row.title,
        body: row.body,
      };
      this.dialog.open(NotificationDialogComponent, notificationDialogConfig);
  }

  navigateToNotifications(){
    this.logService.log(this.userEmail,this.userService.isBrokerUser(),ActivityType.OpenNotificationsSection,"").subscribe(
      (data) => {
      },
      (err) => {
        this.loggingService.logException(err, 4);
      }
    );
    this.router.navigate(['/home/notifications']);
  }

}
