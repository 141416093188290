<div id="full-container">
    <div class="grid-container">
        <div>
            
            <mat-table class="notifications-table mat-elevation-z0" [dataSource]="dataSource">
        
                <ng-container matColumnDef="title">
                    <mat-header-cell *matHeaderCellDef >Title</mat-header-cell>
                    <mat-cell *matCellDef="let notification" class="notification-title"><mat-icon class="notification-icon">notifications_none</mat-icon> {{ (notification.title.length>130)? (notification.title | slice:0:130)+'...':(notification.title) }}</mat-cell>
        
                </ng-container>

                <ng-container matColumnDef="loading">
                  <mat-footer-cell *matFooterCellDef colspan="1">
                    Loading notifications...
                  </mat-footer-cell>
                </ng-container>
                <ng-container matColumnDef="noData">
                  <mat-footer-cell *matFooterCellDef colspan="1">
                    There are no new notifications.
                  </mat-footer-cell>
                </ng-container>
        
        
                <mat-header-row *matHeaderRowDef="displayedColumns" [ngClass]="{'hide':true}" ></mat-header-row>
        
                <mat-row *matRowDef="let row; columns: displayedColumns" (click)="openDetails(row)" class="notification-row"></mat-row>

                <mat-footer-row *matFooterRowDef="['loading']" [ngClass]="{'hide':dataSource!=null}"></mat-footer-row>
                <mat-footer-row *matFooterRowDef="['noData']" [ngClass]="{'hide':!(dataSource!=null && dataSource.data.length==0)}"></mat-footer-row>

                
            </mat-table>

            <button class="moreButton" *ngIf="!loading && dataSource!=null && notificationListCount>2" (click)="navigateToNotifications();" onclick="gtag('event', 'navigateToNotifications', {'event_name': 'Click for more Notifications','screen_name': 'Members List'});">Click for more Notifications</button>
        </div>
        
    </div>
</div>


