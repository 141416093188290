import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DocumentDataService } from '../../services/document-data.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AlertDialogComponent } from 'src/app/glp-common/components/alert-dialog/alert-dialog.component';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { LoggingService } from 'src/app/utilities/logging.service';
import { MemberDataService } from 'src/app/content/home/services/member-data.service';

@Component({
  selector: 'app-documents-list',
  templateUrl: './documents-list.component.html',
  styleUrls: ['./documents-list.component.scss'],
})
export class DocumentsListComponent implements OnInit, AfterViewInit {
  dataSource: MatTableDataSource<any>;

  displayedColumns: string[] = ['name', 'downloadLink'];

  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild(MatSort) sort: MatSort;

  @ViewChild('searchInput') searchInput: ElementRef;

  userEmail:string;
  filterStr: string = '';
  enableSearch: boolean;
  groupId: string = '';
  idType: string;

  constructor(
    private dataService: DocumentDataService,
    public oidcSecurityService: OidcSecurityService,
    private dialog: MatDialog,
    private loggingService: LoggingService,
    private memberData: MemberDataService
  ) {}

  ngOnInit() {
    this.oidcSecurityService.getPayloadFromIdToken().subscribe((account) => {
      if (account) {
        this.groupId = this.memberData.selectedGroupID || account.extension_AccountNumber;
        if (typeof this.groupId == 'undefined' || this.groupId.split(",").length > 1) {
          this.groupId = this.memberData.selectedGroupID?.toString();
        }
        if(this.groupId.length > 6){
          this.groupId =  this.groupId.substring(0,6)
        }
        if(account.emails?.length>0){
          this.userEmail = account.emails[0];
        }

      //default idType is groupId
      if(this.memberData.idType == undefined || this.memberData.idType == null){
        this.idType = 'groupId';
      }
      else{
        this.idType = this.memberData.idType;
      }
        this.dataService.getDocumentList(this.groupId, this.idType).subscribe((list) => {
          let array = list.map((item) => {
            return {
              $key: item.name,
              ...item,
            };
          });
          this.dataSource = new MatTableDataSource(array);
          this.enableSearch = true;
          this.dataSource.filterPredicate = (data, filter) => {
            return this.displayedColumns
              .filter((dc) => dc != 'downloadLink')
              .some((ele) => {
                return data[ele].toLowerCase().indexOf(filter) != -1;
              });
          };
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        });
      }
    });
  }

  ngAfterViewInit() {}

  applyFilter(filterValue: string) {
    this.filterStr = filterValue.trim();
    if(this.dataSource)
    {
      if (filterValue.trim() != "")
      {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
        this.dataSource.filterPredicate = (data: any, filter: string) => {
          var c1 = (typeof data.name !== 'undefined' && data.name !== null && data.name.toLowerCase().includes(filter));
          return c1;
        };
        this.dataSource.filter = filterValue;
      }

      this.dataSource.filter = filterValue;
    }
  }


  clickFilter() {
    this.dataSource.filter = this.searchInput.nativeElement.value.trim().toLowerCase();
  }

  downloadFile(name: string, fullName: string) {
    this.dataService.getDocumentFile(name, fullName, this.userEmail,this.groupId, this.idType).subscribe(
      (data) => {
        //window.open(window.URL.createObjectURL(data));
        const element = document.createElement('a');
        element.href = URL.createObjectURL(data.image);
        element.download = name;
        document.body.appendChild(element);
        element.click();
        element.remove();
      },
      (err) => {
        // console.log(err);
        let friendlyError = 'An error occurred while downloading the document';
        this.loggingService.logException(err, 3);
        const errorDialogConfig = new MatDialogConfig();
        errorDialogConfig.data = {
          title: 'Result Download Document',
          message: friendlyError,
          input: 'Accept',
        };
        this.dialog.open(AlertDialogComponent, errorDialogConfig);
      }
    );
  }
}
