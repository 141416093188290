<div id="full-container">
    <div></div>
    <div class="grid-container">
        <div>

            <h6 id="FormHeading">Forms</h6>
            <div id="searchContainer">
                <input [disabled]="!enableSearch" type="text" class="input-search" (keyup)="applyFilter($event.target.value);" placeholder="Search by Form Group or Name" #searchInput>

                <button [disabled]="!enableSearch" class="searchButton" (click)="clickFilter();" onclick="gtag('event', 'SearchForm', {'event_name': 'Search Form Documents','screen_name': 'Forms List'});">Search</button>
            </div>

            <mat-table class="forms-table mat-elevation-z8" [dataSource]="dataSource" matSort matSortActive="group" matSortDirection="asc" matSortDisableClear>

                <ng-container matColumnDef="group">

                    <mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="{'table-header':true}">Form Group</mat-header-cell>

                    <mat-cell *matCellDef="let form">{{form.group}}</mat-cell>

                </ng-container>

                <ng-container matColumnDef="name">

                    <mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="{'table-header':true}">Form Name</mat-header-cell>

                    <mat-cell *matCellDef="let form">{{form.name}}</mat-cell>

                </ng-container>

                <ng-container matColumnDef="downloadLink">

                    <mat-header-cell *matHeaderCellDef [ngClass]="{'table-header':true}">Actions</mat-header-cell>

                    <mat-cell *matCellDef="let form">

                      <button class="downloadButton" (click)="downloadFile(form.downloadLink,form.name);" onclick="gtag('event', 'downloadForm', {'event_name': 'Download Form','screen_name': 'Forms List'});">
                        <img class="downloadIcon" src="../../../../../assets/images/icons/download3.png" />
                        <span>Download</span>
                      </button>

                      <button class="emailButton" (click)="sendFormEmail(form.downloadLink,form.name);" onclick="gtag('event', 'emailForm', {'event_name': 'Email Form','screen_name': 'Forms List'});">
                        <img class="emailIcon" src="../../../../../assets/images/icons/email-black.png" />
                        <span>Email</span>
                      </button>

                    </mat-cell>

                </ng-container>

                <ng-container matColumnDef="loading">
                    <mat-footer-cell *matFooterCellDef colspan="3">
                      Loading data...
                    </mat-footer-cell>
                  </ng-container>
                  <ng-container matColumnDef="noData">
                    <mat-footer-cell *matFooterCellDef colspan="3">
                      No data.
                    </mat-footer-cell>
                  </ng-container>


                <mat-header-row *matHeaderRowDef="displayedColumns" ></mat-header-row>

                <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

                <mat-footer-row *matFooterRowDef="['loading']" [ngClass]="{'hide':dataSource!=null}"></mat-footer-row>
                <mat-footer-row *matFooterRowDef="['noData']" [ngClass]="{'hide':!(dataSource!=null && dataSource.data.length==0)}"></mat-footer-row>


            </mat-table>


            <mat-paginator [pageSize]="10"
            [pageSizeOptions]="[1, 10, 25, 100]" [showFirstLastButtons]="true"
                           ></mat-paginator>


        </div>

    </div>
    <div></div>
</div>


